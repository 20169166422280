import * as React from "react";
import { useTranslation, Trans } from "react-i18next";
import { Root, ContentContainer, Card, CardContainer, ImagePreview, CardDescription } from "./CommunityResources.styles";
import { Constants } from "../../../../../utilities/Constants";
import { SectionHeading, SectionDescription } from "../Bomberland.styles";
import { TelemetryLink } from "../../../../TelemetryLink/TelemetryLink";
import TutorialPreview from "../../../../../source-filesystem/markdown-pages/29-beginners-tutorial-part-1/tutorial-feature-pt1.jpg";
import CommunityTutorialPreview from "./community-tutorial-preview.jpg";
import { Tag } from "../../../../Tag/Tag";
import { RouterLink } from "../../../../RouterLink/RouterLink";

export const CommunityResources: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <ContentContainer>
                <SectionHeading>{t("bomberlandLandingPage.communityResourcesHeading")}</SectionHeading>
                <SectionDescription>
                    <Trans key={t("bomberlandLandingPage.communityResourcesDescription")}>
                        Plenty of helpful resources to get you started with Bomberland. Get additional help by joining our 800+ member{" "}
                        <TelemetryLink href={Constants.DiscordLink}>Discord community</TelemetryLink>.
                    </Trans>
                </SectionDescription>
                <CardContainer>
                    <Card>
                        <ImagePreview src={TutorialPreview} />
                        <Tag>{t("tag.tutorial")}</Tag>
                        <CardDescription>{t("bomberlandLandingPage.cardADescription")}</CardDescription>
                        <RouterLink href={Constants.BomberlandTutorial}>{t("readMore")} →</RouterLink>
                    </Card>
                    <Card>
                        <ImagePreview src={CommunityTutorialPreview} />
                        <Tag>{t("tag.communityTutorial")}</Tag>
                        <CardDescription>{t("bomberlandLandingPage.cardBDescription")}</CardDescription>
                        <TelemetryLink href={Constants.HajoBomberlandTutorial} target="_blank">
                            {t("readMore")}
                        </TelemetryLink>
                    </Card>
                </CardContainer>
            </ContentContainer>
        </Root>
    );
};
