import styled from "styled-components";
import { Viewport } from "../../../../utilities/Constants/Viewport";
import { Palette } from "../../../../theme/Palette";
import { Font } from "../../../../utilities/Constants/Font";

export const Root = styled.div`
    max-width: 100%;
    box-sizing: border-box;
    position: relative;
    margin-top: -64px;
    padding-top: 64px;
    background: ${Palette.Neutral100};
`;

export const MainContentWrapper = styled.div`
    max-width: 1500px;
    margin: 0px auto;
    padding: 0px 16px;
`;

export const SectionWrapper = styled.div`
    display: grid;
    justify-items: start;
    -webkit-box-align: start;
    align-items: start;
    align-content: space-between;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 36px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    text-align: left;
    background-color: ${Palette.Neutral100};

    @media screen and (max-width: ${Viewport.Large}px) {
        justify-items: center;
    }
`;

export const HorizontalSectionWrapper = styled.div`
    display: grid;
    justify-items: start;
    -webkit-box-align: start;
    align-items: start;
    align-content: space-between;
    grid-auto-columns: 1fr;
    grid-column-gap: 64px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    text-align: left;
    background-color: ${Palette.Neutral100};

    @media screen and (max-width: ${Viewport.Large}px) {
        display: flex;
        flex-direction: column-reverse;
    }

    max-width: 1200px;
    margin: 0px auto;
`;

export const DescriptionWrapper = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
    margin: 0px auto;
    text-align: left;
    justify-items: start;
    -webkit-box-align: start;
    align-items: start;

    @media screen and (max-width: ${Viewport.Large}px) {
        text-align: center;
        align-items: center;
        justify-items: center;
    }
`;

export const Heading1 = styled.h1`
    color: ${Palette.Neutral0};
    font-size: 64px;
    font-weight: 800;
    line-height: 1.2;
    font-family: ${Font.SpaceGrotesk};

    @media screen and (max-width: ${Viewport.Small}px) {
        font-size: 36px;
    }
`;

export const SectionHeading = styled.h2`
    font-family: ${Font.SpaceGrotesk};
    font-size: 48px;
    line-height: 120%;
    font-weight: 700;
    color: ${Palette.Neutral0};

    @media screen and (max-width: ${Viewport.Small}px) {
        font-size: 36px;
    }
`;

export const SectionDescription = styled.p`
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 16px;
    color: ${Palette.White50};
`;

export const Heading2 = styled.h2`
    font-size: 20px;
    line-height: 120%;
    color: ${Palette.White100};
`;

export const Heading3 = styled.h3`
    margin-top: 0;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 120%;
    font-weight: 700;
    margin-top: 16px;
    margin-bottom: 16px;
    color: ${Palette.Neutral0};
`;

export const Tag = styled.p`
    display: inline-block;
    padding: 8px;
    border-radius: 4px;
    background-color: ${Palette.Primary100};
    color: ${Palette.Neutral0};
    font-size: 12px;
    line-height: 120%;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
`;

export const TagStyledOverride = styled.a`
    display: inline-block;
    padding: 8px;
    padding-left: 10px;
    border-radius: 4px;
    background-color: ${Palette.Primary100};
    color: ${Palette.Neutral0};
    font-size: 12px;
    line-height: 120%;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-decoration: none;
    transition: background-color 0.1s;
    justify-self: left;

    &:hover {
        background-color: ${Palette.Primary110};
    }
`;

export const TagWrapper = styled.div`
    display: grid;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    grid-template-columns: repeat(5, max-content);
    align-items: center;
    padding: 8px 0px;

    @media screen and (max-width: ${Viewport.Small}px) {
        display: flex;
        flex-wrap: wrap;
    }
`;

export const Image = styled.img`
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
`;

export const CheckedFeatureLine = styled.div`
    display: grid;
    grid-template-columns: 16px auto;
    grid-column-gap: 8px;
    justify-items: start;
    text-align: left;
    margin-bottom: 16px;
`;
