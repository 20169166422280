import * as React from "react";
import { useTranslation } from "react-i18next";
import { SectionHeading, SectionDescription } from "../Bomberland.styles";
import { InverseButton } from "../../../../InverseButton/InverseButton";
import { RouterLink } from "../../../../RouterLink/RouterLink";
import { CoderOneRoute } from "../../../../Pages/CoderOneRoute";
import { CallToActionSection } from "../../../../CallToActionSection/CallToActionSection";

export const CallToAction: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    return (
        <CallToActionSection>
            <SectionHeading>{t("bomberlandLandingPage.joinBomberland")}</SectionHeading>
            <SectionDescription>{t("bomberlandLandingPage.joinBomberlandDescription")}</SectionDescription>
            <RouterLink href={CoderOneRoute.Pricing}>
                <InverseButton as="div">{t("bomberlandLandingPage.getAccess")}</InverseButton>
            </RouterLink>
        </CallToActionSection>
    );
};
