import styled from "styled-components";
import { Palette } from "../../../../../theme/Palette";

export const Root = styled.div`
    max-width: 100%;
    padding: 64px 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ContentContainer = styled.div`
    background-color: ${Palette.White10};
    border-radius: 4px;
    padding: 64px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    text-align: center;
    align-items: center;
`;

export const IconWrapper = styled.span`
    color: ${Palette.Primary100};
    font-size: 36px;
    line-height: 100%;
`;
