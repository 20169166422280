import * as React from "react";
import { Footer } from "../../../Footer/Footer";
import { NavigationHeader } from "../../../NavigationHeader/NavigationHeader";
import { SEO } from "../../../SEO/SEO";
import { useTranslation } from "react-i18next";
import { Root, MainContentWrapper } from "./Bomberland.styles";
import { Hero } from "./Hero/Hero";
import { LeaderboardPreview } from "../../../LeaderboardPreview/LeaderboardPreview";
import { FeaturesB } from "./FeaturesB/FeaturesB";
import { OpenSource } from "./OpenSource/OpenSource";
import { CommunityResources } from "./CommunityResources/CommunityResources";
import { CallToAction } from "./CallToAction/CallToAction";
import bomberlandSocialPreview from "./bomberland-preview.jpg";

const Home: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const description = t("bomberlandLandingPage.bomberlandDescription");
    const title = t("bomberland.title");
    const socialImage = `https://${process.env.GATSBY_HOST}${bomberlandSocialPreview}`;
    return (
        <React.Fragment>
            <SEO title={title} description={description} socialImage={socialImage} />
            <NavigationHeader isDarkTheme={true} />
            <Root>
                <Hero />
                <MainContentWrapper>
                    <LeaderboardPreview />
                    <FeaturesB />
                    <OpenSource />
                    <CommunityResources />
                </MainContentWrapper>
                <CallToAction />
            </Root>
            <Footer isDarkTheme={true} />
        </React.Fragment>
    );
};

export default Home;
