import styled from "styled-components";
import { Palette } from "../../../../../theme/Palette";
import { Viewport } from "../../../../../utilities/Constants/Viewport";

export const Root = styled.div`
    max-width: 100%;
    padding: 64px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    text-align: center;
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
    
    @media screen and (max-width: ${Viewport.Medium}px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const Card = styled.div`
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-row-gap: 16px;
    justify-items: center;
    justify-self: center;
    background-color: ${Palette.White10};
    padding: 16px;
    max-width: 320px;
    border-radius: 4px;
`;

export const IconWrapper = styled.span`
    color: ${Palette.Primary100};
    font-size: 36px;
    line-height: 100%;
`;

export const ImagePreview = styled.img`
    width: 100%;
`;

export const CardDescription = styled.p`
    color: ${Palette.White50};
`;
