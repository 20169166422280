import * as React from "react";
import { useTranslation } from "react-i18next";
import { Root, ContentContainer, IconWrapper } from "./OpenSource.styles";
import { GitHubButton } from "../../../../GitHubButton/GitHubButton";
import { Constants } from "../../../../../utilities/Constants";
import { HeartIcon } from "../../../../Icons/HeartIcon";
import { SectionHeading, SectionDescription } from "../Bomberland.styles";

export const OpenSource: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <ContentContainer>
                <IconWrapper>
                    <HeartIcon />
                </IconWrapper>
                <SectionHeading>{t("bomberlandLandingPage.openSourceHeading")}</SectionHeading>
                <SectionDescription>{t("bomberlandLandingPage.openSourceDescription")}</SectionDescription>
                <GitHubButton href={Constants.BomberlandRepo} />
            </ContentContainer>
        </Root>
    );
};
