import * as React from "react";
import { useTranslation } from "react-i18next";
import {
    Heading1,
    SectionDescription,
    TagWrapper,
    Image,
    HorizontalSectionWrapper,
    DescriptionWrapper,
    TagStyledOverride,
    CheckedFeatureLine,
    Heading2,
} from "../Bomberland.styles";
import { Root } from "./FeaturesB.styles";
import StarterKitPreview from "./bomberland-starter-kit.png";
import { Constants } from "../../../../../utilities/Constants";
import { TelemetryLink } from "../../../../TelemetryLink/TelemetryLink";
import { RoundCheckIcon } from "../../../../Icons/RoundCheckIcon";

export const FeaturesB: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <HorizontalSectionWrapper>
                <Image src={StarterKitPreview} alt="Preview of Bomberland Python starter kit" role="presentation" />
                <DescriptionWrapper>
                    <Heading1>{t("bomberlandLandingPage.tryAnyStrategy")}</Heading1>
                    <Heading2>{t("bomberlandLandingPage.tryAnyStrategyDescription")}</Heading2>
                    <SectionDescription>
                        <CheckedFeatureLine>
                            <RoundCheckIcon />
                            <span>{t("bomberlandLandingPage.simplePlatform")}</span>
                        </CheckedFeatureLine>
                        <CheckedFeatureLine>
                            <RoundCheckIcon />
                            <span>{t("bomberlandLandingPage.forwardModel")}</span>
                        </CheckedFeatureLine>
                        <CheckedFeatureLine>
                            <RoundCheckIcon />
                            <span>
                                {t("bomberlandLandingPage.useOurStarterKits")}
                                <TagWrapper>
                                    <TelemetryLink
                                        href={Constants.BomberlandStarterKits}
                                        target="_blank"
                                        styledOverride={TagStyledOverride}
                                    >
                                        {t("python3")}
                                    </TelemetryLink>
                                    <TelemetryLink
                                        href={Constants.BomberlandStarterKits}
                                        target="_blank"
                                        styledOverride={TagStyledOverride}
                                    >
                                        {t("typescript")}
                                    </TelemetryLink>
                                    <TelemetryLink
                                        href={Constants.BomberlandStarterKits}
                                        target="_blank"
                                        styledOverride={TagStyledOverride}
                                    >
                                        {t("golang")}
                                    </TelemetryLink>
                                    <TelemetryLink
                                        href={Constants.BomberlandStarterKits}
                                        target="_blank"
                                        styledOverride={TagStyledOverride}
                                    >
                                        {t("cplusplus")}
                                    </TelemetryLink>
                                    <TelemetryLink href={Constants.ContributeStarterKit} target="_blank" styledOverride={TagStyledOverride}>
                                        {t("bomberlandLandingPage.contribute")}
                                    </TelemetryLink>
                                </TagWrapper>
                            </span>
                        </CheckedFeatureLine>
                    </SectionDescription>
                </DescriptionWrapper>
            </HorizontalSectionWrapper>
        </Root>
    );
};
