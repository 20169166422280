import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Heading1 } from "../Bomberland.styles";
import { Root, HeadingSpan, DemoGif, Description, DescriptionWrapper, GridContainer } from "./Hero.styles";
import BomberlandPreview from "../../../../Images/bomberland-preview.gif";
import { GitHubButton } from "../../../../GitHubButton/GitHubButton";
import { Constants } from "./../../../../../utilities/Constants";

export const Hero: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <GridContainer>
                <DescriptionWrapper>
                    <Heading1>
                        <Trans key="bomberlandLandingPage.headline">
                            <HeadingSpan>Welcome to </HeadingSpan>Bomberland
                        </Trans>
                    </Heading1>
                    <Description>
                        {t("bomberlandLandingPage.bomberlandDescription")}
                        <br />
                        <br />
                        {t("bomberlandLandingPage.environmentGoal")}
                    </Description>
                    <GitHubButton label={t("bomberlandLandingPage.githubButton")} href={Constants.BomberlandRepo} />
                </DescriptionWrapper>
                <DemoGif src={BomberlandPreview} loading="lazy" alt={t("demoAnimationAlt")} />
            </GridContainer>
        </Root>
    );
};
