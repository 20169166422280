import styled from "styled-components";
import { Palette } from "../../../../../theme/Palette";
import HeroBackground from "./pixel-space-tile.png";
import { Viewport } from "../../../../../utilities/Constants/Viewport";

export const Root = styled.div`
    background-image: linear-gradient(0deg, ${Palette.Neutral100} 10%, transparent 30%),
        radial-gradient(${Palette.Accent10}, transparent 40%), url(${HeroBackground});
    background-position: 50% 90%;
    background-repeat: repeat;
    background-attachment: scroll, scroll, fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -64px;
    padding: 128px 16px 0px 16px;
`;

export const HeadingSpan = styled.span`
    color: ${Palette.White50};
`;

export const DemoGif = styled.img`
    border-radius: 4px;
    border: 1px solid ${Palette.Neutral50};
    max-width: 100%;
    justify-self: end;

    @media screen and (max-width: ${Viewport.Large}px) {
        justify-self: center;
    }
`;

export const Description = styled.p`
    color: ${Palette.Neutral0};
    font-size: 20px;

    @media screen and (max-width: ${Viewport.Small}px) {
        font-size: 16px;
    }
`;

export const DescriptionWrapper = styled.div`
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
    margin: 0px auto;
    justify-items: inherit;
`;

export const GridContainer = styled.div`
    display: grid;
    -webkit-box-align: start;
    grid-column-gap: 32px;
    grid-template-columns: 1fr 1fr;
    max-width: 1200px;
    padding: 128px 0;
    justify-items: start;
    align-items: center;

    @media screen and (max-width: ${Viewport.Large}px) {
        grid-template-columns: 1fr;
        justify-items: center;
        text-align: center;
        padding-top: 64px;
        grid-row-gap: 64px;
        width: 90vw;
    }
`;

export const ButtonWrapper = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 16px;

    @media screen and (max-width: ${Viewport.Medium}px) {
        grid-template-columns: 1fr;
        justify-items: center;
        text-align: center;
        grid-row-gap: 16px;
    }
`;
